export const defaultColumns = [
  {
    title: '企業名稱',
    dataIndex: 'corpName'
  },
  {
    title: '企業類型',
    dataIndex: 'corpType',
    scopedSlots: { customRender: 'corpType' }
  },
  {
    title: '企業頭像',
    dataIndex: 'corpSquareLogoUrl',
    scopedSlots: { customRender: 'corpSquareLogoUrl' }
  },
  {
    title: '企業規模',
    dataIndex: 'corpScale'
  },
  {
    title: '所屬行業',
    dataIndex: 'corpIndustry'
  },
  {
    title: '應用編號',
    dataIndex: 'agentId'
  },
  {
    title: '登錄次數',
    dataIndex: 'loginNum',
    scopedSlots: { customRender: 'amount' }
  },
  {
    title: '使用人數',
    dataIndex: 'amount',
    scopedSlots: { customRender: 'amount' }
  },
  {
    title: '使用狀態',
    dataIndex: 'deleteFlag',
    scopedSlots: { customRender: 'deleteFlag' }
  },
  {
    title: '安裝時間',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    title: '到期時間',
    dataIndex: 'expireTime',
    scopedSlots: { customRender: 'expireTime' }
  },
  // {
  //   title: '最近一次卸載時間',
  //   dataIndex: 'deleteTime',
  //   scopedSlots: { customRender: 'deleteTime' }
  // },
  {
    title: '授權狀態',
    dataIndex: 'openFlag',
    scopedSlots: { customRender: 'openFlag' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed:'right',
    scopedSlots: { customRender: 'action' }
  }
]
export const corpTypeOptiosn = [
  {
    label: '認證號',
    value: 'verified'
  },
  {
    label: '註冊號',
    value: 'unverified'
  }
]
export const deleteFlagOptions = [
  {
    label: '正常',
    value: 'Y'
  },
  {
    label: '已卸載',
    value: 'N'
  }
]
