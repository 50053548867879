//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      visible: false,
      mess: {},
    }
  },
  methods: {
    handleOk() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    },
    open(mess) {
      this.mess = mess
      this.visible = true
    },
  },
}
