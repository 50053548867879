//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { wxCorpPage, wxOvertime } from '@/api/modular/fileStream/documentManage'
import { STable } from '@/components'
import moment from 'moment'
import setMess from './components/setMess.vue'
import showMoreMess from './components/showMoreMess.vue'
import { corpTypeOptiosn, defaultColumns, deleteFlagOptions } from './config'
export default {
    components: {
        STable,
        setMess,
        showMoreMess,
    },
    data() {
        return {
            dateFormat: 'YYYY-MM-DD HH:mm:ss',
            columns: defaultColumns,
            corpTypeOptiosn,
            deleteFlagOptions,
            queryParam: {
                // corpType: '',
                // deleteFlag: '',
                searchValue: '',
                expireTime: [],
            },
            func: wxCorpPage,
            loadData: (parameter) => {
                const { corpType, deleteFlag, searchValue, expireTime } = this.queryParam
                const params = {
                    corpType,
                    deleteFlag,
                    searchValue,
                    searchBeginTime: expireTime ? expireTime[0] : '',
                    searchEndTime: expireTime ? expireTime[1] : '',
                }
                return this.func(Object.assign(parameter, params)).then((res) => res.data)
            },
            selectedRowKeys: [],
        }
    },
    methods: {
        moment,
        // 處理日期
        disabledDateHandler(current) {
            return current < moment().subtract(1, 'days').endOf('day')
        },
        onSearch() {},
        setMess({ id }) {
            this.$refs.setMess.open(id)
        },
        more({ permanentCode, corpId, deleteTime, openTime }) {
            this.$refs.showMoreMess.open({ permanentCode, corpId, deleteTime, openTime })
        },
        reset() {
            this.queryParam = {
                corpType: '',
                deleteFlag: '',
                searchValue: '',
                expireTime: [],
            }
            this.$refs.table.refresh()
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys
        },
        foramtCorpType(text) {
            return this.corpTypeOptiosn.find((j) => j.value === text)?.label
        },
        formatTime(time) {
            if (!time) return ''
            const timeStr = time.replace(/-/g, '/')
            return new Date(timeStr).toLocaleString().replace(/\//g, '-')
        },
        foramtDeleteFlag(text) {
            return this.deleteFlagOptions.find((j) => j.value === text)?.label
        },
        // 修改服務時間
        updateExpireTime(time, { id }) {
            let params = {
                id,
                expireTime: time.format(this.dateFormat),
            }
            wxOvertime({ ...params }).then((res) => {
                const { success } = res
                if (success) {
                    this.$message.success('修改成功')
                    this.$refs.table.refresh(true)
                }
            })
        },
    },
}
