//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  wxCorpEdit
} from '@/api/modular/fileStream/documentManage'
export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: 'setMess' }),
      visible: false,
      messId: ''
    }
  },
  methods: {
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
          wxCorpEdit({
            amount: values.amount,
            expireTime: `${values.expireTime} 00:00:00`,
            id: this.messId
          })
          .then(res => {
            if (res.code === 200) {
              this.$message.success('編輯成功')
              this.$emit('refresh')
              this.visible = false
            }
          })
        }
      })
    },
    handleCancel() {
      this.visible = false
    },
    open(id) {
      this.visible = true
      this.messId = id
    }
  }
}
